import state from "../../state";

/**
 * Handles behavior for updating application files when a new deployment is detected.
 */
const SubrouteManagerMixin = {
  created() {
    if (!Array.isArray(this.subroutes)) {
      const msg = `Invalid implementation of "SubrouteManagerMixin."
            Make sure your component has a "subroutes" array property. (It can
            be an empty array if the route has no sub-sections).`;
      throw new Error(msg);
    }
  },

  beforeDestroy() {
    return state.subroutes([]);
  },

  methods: {
    broadcastSubroutes() {
      state.subroutes(this.subroutes);
    }
  }
};

export default SubrouteManagerMixin;
