<template>
  <loader v-if="loading" />

  <section v-else id="users" class="route">
    <!-- heading -->
    <h1 class="title">
      Users
      <hr class="divider divider--vertical" />
      <i class="fas fa-arrow-right grey-light--text"></i>
      <hr class="divider divider--vertical" />
      <span class="accent--text">{{ subRouteName }}</span>
    </h1>

    <hr class="divider grey-light" />

    <div v-if="$route.name === 'Users' && isAdminUser">
      <div class="list-item">
        View or manage user access to the
        <hr class="divider divider--vertical" />
        <b>{{ appOrgName }}</b>
        <hr class="divider divider--vertical" />
        application.
      </div>

      <div class="list-item">
        You can proceed using any link in the action menu above.
      </div>
    </div>

    <!-- Route -->
    <router-view></router-view>
  </section>
</template>

<script>
/* eslint-disable no-undef */
import Loader from "../components/Loader.vue";
import PermissionsMixin from "../components/mixins/permissions.mixin";
import SubrouteManagerMixin from "../components/mixins/subroute-manager.mixin";

export default {
  name: "Users",

  components: { Loader },

  mixins: [PermissionsMixin, SubrouteManagerMixin],

  computed: {
    subroutes() {
      return [
        { name: "ListUsers", icon: "fas fa-users", condition: true },
        {
          name: "ZoomUserSettings",
          icon: "fas fa-video grey-light--text",
          condition: this.isSuperAdminUser
        },
        {
          name: "CreateUser",
          icon: "fas fa-user-plus success--text",
          condition: true
        }
      ].filter(({ condition }) => condition);
    }
  },

  async mounted() {
    this.broadcastSubroutes();
    this.attachToState();
  }
};
</script>
